// ** main component
const RefreshToast = (props) => {
   console.log(props)
   return (
      <div
         className="refresh-toast"
         style={{
            minWidth: "40vw",
            borderRadius: "10px",
            overflow: "hidden"
         }}
      >
         <div className="refresh-toast-top">
            {props.title} Disconnected
            <b className="toast-close" onClick={props.onClick}>
               X
            </b>
         </div>
         <div className="refresh-toast-bottom">Please restart server</div>
      </div>
   )
}
export default RefreshToast
